import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { RichText } from "./rich-text";
import Button from "./button";
import { Asset } from "./asset";
import replaceUrl from "replace-url-by-params";
import queryString from "query-string";

export interface CardProps {
  name: string;
  logo: {
    title: string;
    localFile: {
      childImageSharp: {
        gatsbyImageData: any; // Replace with the actual type if available
      };
    };
  };
  cardText: {
    raw: string;
  };
  stars: number;
  link: string;
  location: any;
}

const GameCard: React.FC<CardProps> = ({
  name,
  logo,
  cardText,
  stars,
  link,
  location
}) => {
  const {
    taboola_click_id,
    click_id,
    campaign,
    utm_source,
    adgroup,
    campaign_item_id,
    creative,
  }: any = queryString.parse(location.search);

  const [finalLink, setFinalLink] = useState("");

  useEffect(() => {
    setFinalLink(
      replaceUrl(link, {
        network_name: utm_source,
        campaign_name: campaign,
        adgroup_name: adgroup,
        site: adgroup,
        taboola_click_id: taboola_click_id || click_id,
        click_id: taboola_click_id || click_id,
        campaign_item_id: creative || campaign_item_id,
        creative,
      })
    );
  }, []);

  const renderStars = (stars: number) => {
    const fullStars = Math.floor(stars); // Number of full stars
    const hasHalfStar = stars % 1 !== 0; // Check if there's a half-star
    const totalStars = 5; // Maximum number of stars

    return (
      <div className="stars flex items-center">
        {Array.from({ length: fullStars }).map((_, i) => (
          <span key={`star-full-${i}`} className="text-yellow-500 text-xl">
            <StaticImage key={i} src="../images/green-star.png" alt="star" />
          </span>
        ))}
        {hasHalfStar && (
          <StaticImage src="../images/half-star.png" alt="star" />
        )}
        {Array.from({ length: totalStars - Math.ceil(stars) }).map((_, i) => (
          <span key={`star-empty-${i}`} className="text-gray-300 text-xl">
            <StaticImage src="../images/not-star.png" alt="star" />
          </span>
        ))}
      </div>
    );
  };
  return (
    <div className="game-card px-10 mb-4">
      <div className="game-card-container text-center pb-4">
        <div className="flex justify-between items-center mb-4">
          <div className="logo p-4">
            {logo && <Asset className="w-[130px]" data={logo} />}
          </div>
          <div className="mr-[20px]">{renderStars(stars)}</div>
        </div>
        <h2 className="text-[#54D400] text-[16px] text-center font-bold mb-2">
          {name}
        </h2>

        <RichText contentfullText={cardText} />
        <Button
          title="Play Now"
          url={finalLink}
          color={"linear-gradient(180deg, #54D400 30%, #A8FF00 100%)"}
          fontSize={14}
          classes={"rounded-[4px] px-6 py-1"}
        />
      </div>
    </div>
  );
};

export default GameCard;
