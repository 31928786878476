import React from "react"
import { graphql } from "gatsby"

// Components
import {
  RichText,
  Asset,
} from "../../components"
import GameCard, { CardProps } from "../../components/GameCard";

// Styles
import "./styles.css";

const Page = ({ data, location }: any) => {
  const {
    logo,
    hero,
    textUnderHero,
    cards
  } = data.contentfulTaboolaPageV2

  return (
    <main>
      <article className="container m-auto py-3 flex flex-col gap-3">
        <Asset className="w-[165px] m-auto my-[20px]" data={logo} />
        <div className="hero relative bg-black mb-[20px]">
          <Asset className="opacity-50" data={hero.image} />
          <div className="px-10 absolute bottom-[25%]">
            <RichText
              contentfullText={hero.title}
            />
          </div>
          <div className="px-10 absolute bottom-[10%] flex items-center gap-2">
            <Asset data={hero.addByImage} />
            <p className="text-white text-[10px]">By: {hero.addBy}</p>
          </div>
        </div>
        <div className="text-under-hero px-10">
          <RichText
              contentfullText={textUnderHero}
            />
        </div>
        {cards.map((card: CardProps) => <GameCard {...card} location={location} />) }
        <div className="text-[8px] opacity-50 text-center">
          This content is a promotional advertisement and not a news story
        </div>
      </article>
    </main>
  )
}

export const Head = ({ data }: any) => {
  return (
    <>
      <title>{data.contentfulTaboolaPageV2.name}</title>
    </>
  )
}

export const data = graphql`
  query defaultPage($slug: String) {
    contentfulTaboolaPageV2(slug: { eq: $slug }) {
      name
      logo {
        title
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED)
          }
        }
      }
      hero {
        title {
          raw
        }
        addBy
        addByImage {
          title
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED, width: 24)
            }
          }
        }
        image {
          title
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED)
            }
          }
        }
      }
      textUnderHero {
        raw
      }
      cards {
        name,
        logo {
          title
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED)
            }
          }
        }
        cardText {
          raw
        }
        stars
        link
      }
    }
  }
`

export default Page
